import React from 'react'
import juice from 'juice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { usePermitted } from '../../hooks/useNavigatorPermissions'
import Layout from '../../components/Layouts/Layout'
import SEO from '../../components/SEO'
import { VerticalSpace } from '../../components/VerticalSpace'

const title = "CSS Inliner Tool"
const description = "Using inline styles for HTML and CSS ensures that your emails display just as you want them to. Inline CSS with our inliner tool to ensure email clients don't strip out your styles. Get inlining."

const CssInliner: React.FunctionComponent = () => {
  const [value, setValue] = React.useState("")

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }, [setValue])

  const output = React.useMemo(() => {
    return juice(value)
  }, [value])

  const pastePermitted = usePermitted('clipboard-read' as any)

  const paste = React.useCallback(() => {
    navigator.clipboard.readText().then(setValue).catch(console.error)
  }, [])

  const pasteButton = React.useMemo(() => {
    if (!pastePermitted) return null
    return (
      <button type="button" onClick={paste} title="paste" className="btn btn-secondary">
        <FontAwesomeIcon icon={faClipboard} />
      </button>
    )
  }, [pastePermitted, paste])

  const copyPermitted = usePermitted('clipboard-write' as any)

  const copy = React.useCallback(() => {
    navigator.clipboard.writeText(output).catch(console.error)
  }, [output])

  const copyButton = React.useMemo(() => {
    if (!copyPermitted) return null
    return (
      <button type="button" onClick={copy} title="copy" className="btn btn-secondary">
        <FontAwesomeIcon icon={faClipboard} />
      </button>
    )
  }, [copyPermitted, copy])

  return (
    <Layout title={title} className="css-inliner">
      <SEO title={title} description={description} />
      <p className="py-5">
        While the web has moved on from tables and inlined CSS, email code is a little old fashioned; some email clients will strip out external style sheets or embedded styles completely. Use the CSS inliner to ensure your emails display beautifully across every client and every device, every time.
      </p>
      <form>
        <div className="form-group">
          <label>Paste your HTML here</label>
          {pasteButton}
          <textarea value={value} onChange={handleChange} className="form-control" />
        </div>
        <div className="form-group">
          <label>Copy your Inlined HTML from here</label>
          {copyButton}
          <textarea value={output} className="form-control" readOnly />
        </div>
      </form>

      <VerticalSpace height={50} />
      <h3>
        Want to know more about inlining?
      </h3>
      <p>
        Inlining HTML is a crucial step to prepare HTML emails before you press that big ol' send button thanks to some email clients that strip out styles unless they're embedded in style tags. Doing this by hand is a pain in the butt. It's also prone to human error - although shockingly, in 2018 Litmus conducted a study that found that <a href="https://www.litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css/" target="_blank">70% of marketers were still inlining CSS by hand</a>. Life is too short for that, my friends!
      </p>
      <p>
        While we've used other providers' inliners before (including Mailchimp, Litmus and Campaign Monitor just to name a few), we found the results were unpredictable when we tested these across devices and email clients. It was frustrating. Words were said. Desks were flipped. (There's only a smidge of hyperbole in this story.)
      </p>
      <p>
        Luckily, we found an open source CSS inliner called <a href="https://github.com/Automattic/juice" target="_blank">Juice</a>. What sweet relief. We've used Juice on our own site because we use it so often, along with our other email tools. If you'd like to play more with these Juice's CSS settings, you can tweak them yourself at <a href="https://automattic.github.io/juice/" target="_blank"> Juice CSS Inliner</a>. If you want to learn more about <a href="https://www.litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css/"> inlining CSS, Litmus has a great blog</a>.
      </p>
      <p>
        It's important to test your own emails using Litmus or Emails on Acid and make sure whatever you're using works for you. Not all CSS inliners are created equal. We speak from experience!
      </p>

      <VerticalSpace height={100} />
    </Layout>
  )
}

export default CssInliner
