import React from 'react'

type AsyncPermissionState = PermissionState | 'pending' | 'failed'

export function usePermissionState(name: PermissionName) {
  const [permissionState, setPermissionState] = React.useState<AsyncPermissionState>('pending')

  React.useEffect(() => {
    navigator.permissions.query({ name }).then((permissionStatus) => {
      setPermissionState(permissionStatus.state)
      permissionStatus.addEventListener('change', () => {
        setPermissionState(permissionStatus.state)
      })
    }).catch((error) => {
      console.error(error)
      setPermissionState('failed')
    })
  })

  return permissionState
}

export function usePermitted(name: PermissionName) {
  const permissionState = usePermissionState(name)
  return React.useMemo(() => {
    return ["granted", "prompt"].includes(permissionState)
  }, [permissionState])
}
